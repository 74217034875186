footer {
	padding-top: 164px;
	padding-bottom: 164px;
	font-size: 18px;
}

footer a {
	transition: all 150ms ease-in;
	color: #000000;
	border-color: #00000059;
}

footer a:hover {
	border-color: #000000;
}

.footer {
	display: flex;
	flex-wrap: wrap;
	font-size: 16px;
	line-height: 1.3;
}

.footer__column {
	width: calc(50% - 8px);
	position: relative;
	margin-bottom: 48px;
}

@media (min-width: 768px) {
	.footer__column {
		width: calc(33% - 8px);
		margin-bottom: 0;
	}
}

.footer__column:not(:last-child) {
	margin-right: 8px;
}

.footer__link {
	margin-bottom: 8px;
}

.footer__header {
	font-weight: 800;
	margin-bottom: 8px;
}

.footer__header:not(:first-child) {
	margin-top: 48px;
}

.footer__link--copyright {
	position: static;
	bottom: 0;
	margin-top: 24px;
}

@media (min-width: 768px) {
	.footer__link--copyright {
		position: absolute;
		margin-top: 0;
	}
}
