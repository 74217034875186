@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Oswald:400);
.text-container {
	margin: 0 auto;
	max-width: 45rem;
	margin-bottom: 1rem;
	padding-left: 16px;
	padding-right: 16px;
}

@media (min-width: 768px) {
	.text-container {
		max-width: 960px;
	}
}

.logotype-mini {
	letter-spacing: 0.04rem;
	margin: 0 1rem 0 .3rem;
}

a.logotype-mini__text {
	color: #000;
	border: none;
}

a.logotype-mini__text:hover {
	color: #283593;
}

.main-header {
	position: sticky;
	top: 0;
	z-index: 2;
	-webkit-backdrop-filter: blur(6px);
	        backdrop-filter: blur(6px);
	background-color: rgba(255, 255, 255, .70);
	-webkit-box-shadow:
		0 2px 4px -2px rgb(0, 0, 0, .05),
		0 6px 12px -4px rgb(0, 0, 0, .05),
		0 18px 36px -8px rgb(0, 0, 0, .05)
	;
	        box-shadow:
		0 2px 4px -2px rgb(0, 0, 0, .05),
		0 6px 12px -4px rgb(0, 0, 0, .05),
		0 18px 36px -8px rgb(0, 0, 0, .05)
	;
	font-size: 1rem;
}

.main-header__container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-ms-flex-line-pack: center;
	    align-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	padding-left: 16px;
	padding-right: 0;
	margin-bottom: 0;
}

.main-header__menu {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: nowrap;
	    flex-wrap: nowrap;
	position: relative;
}

@media (max-width: 768px) {
	.main-header__menu {
		overflow: auto;
		-webkit-box-shadow: -30px 0 30px -30px #8197a738 inset;
		        box-shadow: -30px 0 30px -30px #8197a738 inset;
		width: 100%;
	}
}

.main-header__menu a {
	color: #000;
	border-radius: 4px;
    padding: .2em .6em;
    margin-left: -.6rem;
	margin-right: .7rem;
	border: none;
}

.main-header__menu a:hover {
	background: rgba(0, 0, 0, .04);
}

.main-header__link {
	border: 0;
	color: #000;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.main-header__avatar {
	max-height: 3rem;
	padding: 0.3rem 0;
	border-radius: 100%;
}

@media (max-width: 576px) {
	.main-header__avatar {
		padding: 0;
		width: 32px;
		height: 32px;
	}
}

footer {
	padding-top: 164px;
	padding-bottom: 164px;
	font-size: 18px;
}

footer a {
	-webkit-transition: all 150ms ease-in;
	transition: all 150ms ease-in;
	color: #000000;
	border-color: #00000059;
}

footer a:hover {
	border-color: #000000;
}

.footer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	font-size: 16px;
	line-height: 1.3;
}

.footer__column {
	width: calc(50% - 8px);
	position: relative;
	margin-bottom: 48px;
}

@media (min-width: 768px) {
	.footer__column {
		width: calc(33% - 8px);
		margin-bottom: 0;
	}
}

.footer__column:not(:last-child) {
	margin-right: 8px;
}

.footer__link {
	margin-bottom: 8px;
}

.footer__header {
	font-weight: 800;
	margin-bottom: 8px;
}

.footer__header:not(:first-child) {
	margin-top: 48px;
}

.footer__link--copyright {
	position: static;
	bottom: 0;
	margin-top: 24px;
}

@media (min-width: 768px) {
	.footer__link--copyright {
		position: absolute;
		margin-top: 0;
	}
}

:root {
	--text-block-width: 45rem;
}

body * {
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
}

body {
	font: 400 14pt/1.4 PT Sans, Tahoma, Sans-Serif;
	margin: 0;
}

@media (max-width: 500px) {
	body {
		font-size: 13pt;
	}
}

.container {
	margin: 0 auto;
	margin-bottom: 1rem;
	max-width: 80rem;
	padding-left: 1rem;
	padding-right: 1rem;
}

@media (min-width: 576px) {
	.container {
		padding-left: 0;
		padding-right: 0;
	}
}

.container--image-text {
	max-width: 55rem;
	margin: 1cm auto;
}

.container__fluid {
	min-height: 1px;
}

.container--image-row,
.image-gallery {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	max-width: 100%;
	overflow: hidden;
	-webkit-box-align: start;
	    -ms-flex-align: start;
	        align-items: flex-start;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}
.container--image-text {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.container--image-text__image {
	width: 75%;
}

.container--image-text__text--right,
.container--image-text__text--left {
	width: 25%;
}

.container--image-text__text--right {
	margin-left: 15px;
}

.container--image-text__text--left {
	margin-right: 15px;
}

.container--custom-background {
	padding: calc(15px * 7) 0;
}

.container--white {
	background: #fff;
}

.container--margin {
	padding: 1rem;
}

a {
	text-decoration: none;
	border-bottom: 1px solid #0000ee59;
}

a:hover {
	border-color: #00e;
}

p {
	margin: 0 0 1rem 0;
}

.about {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	max-width: var(--text-block-width);
	margin: 0 auto;
}

.about__description {
	max-width: 100%;
	z-index: 1;
}

.project.container {
	max-width: 100%;
}

.project__list {
	margin: 30px auto 100px auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-ms-flex-line-pack: start;
	    align-content: flex-start;
	-webkit-box-align: start;
	    -ms-flex-align: start;
	        align-items: flex-start;
}

.page.project__list {
	display: block;
}

.page.magic {
	background: rgba(70, 0, 105, 0.93);
}

.page.magic header {
	color: #fff;
	fill: #fff;
}

.page.magic footer {
	background: -webkit-gradient(
		linear,
		left top, left bottom,
		from(rgb(83, 18, 115)),
		color-stop(23%, rgb(135, 45, 179)),
		color-stop(54%, rgb(168, 111, 195)),
		to(rgb(240, 209, 255))
	);
	background: linear-gradient(
		180deg,
		rgb(83, 18, 115) 0%,
		rgb(135, 45, 179) 23%,
		rgb(168, 111, 195) 54%,
		rgb(240, 209, 255) 100%
	);
	color: #fff;
}

.page.magic footer a {
	color: #fff;
	opacity: .5;
	-webkit-transition: ease-in 100ms;
	transition: ease-in 100ms;
}

.page.magic footer a:hover {
	opacity: 1;
}

.magic-ball {
    position: relative;
    width: 100%;
    height: 70vh;
}

.epos {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	z-index: 10;
}

.eball {
	height: 400px;
	width: 400px;
	background: rgba(30, 30, 30, 0.69);
	border-radius: 100%;
	overflow: hidden;
	position: relative;
	-webkit-box-shadow:
		inset -30px 40px 90px -50px rgba(255, 255, 255, 0.4),
		inset 5px -30px 70px 20px rgb(17, 0, 29),
		inset 5px -5px 50px rgba(0, 0, 0, 1),
		inset 50px -50px 130px rgba(0, 0, 0, 1),
		inset 0px -20px 20px rgba(60, 0, 60, 1)
	;
	        box-shadow:
		inset -30px 40px 90px -50px rgba(255, 255, 255, 0.4),
		inset 5px -30px 70px 20px rgb(17, 0, 29),
		inset 5px -5px 50px rgba(0, 0, 0, 1),
		inset 50px -50px 130px rgba(0, 0, 0, 1),
		inset 0px -20px 20px rgba(60, 0, 60, 1)
	;
}

.illuminator {
	position: absolute;
	background: linear-gradient(45deg, rgba(20, 0, 20, 1) 0%, rgba(40, 0, 40, 1) 100%);
	width: 160px;
	height: 160px;
	border-radius: 100%;
	border: solid 4px rgba(20, 20, 20, 0.8);
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.triangle {
	-webkit-transform: translate(-50%, -50%) rotate(5deg);
	        transform: translate(-50%, -50%) rotate(5deg);
	position: absolute;
	width: 0px;
	height: 0px;
	left: 50%;
	top: 60%;
	border-left: 55px solid transparent;
	border-right: 55px solid transparent;
	border-top: 90px solid rgba(60, 10, 120, 0.0);
	opacity: 0;
	-webkit-transition: ease-in all 0.2s;
	transition: ease-in all 0.2s;
}

.textbox {
	font: 400 11px Oswald;
	text-shadow: 1px 1px 2px rgba(40, 0, 200, 0.8);
	color: rgba(240, 220, 240, 0.8);
	text-align: center;
	line-height: 1.1;
	-webkit-transform: translate(-50%, -50%) rotate(5deg);
	        transform: translate(-50%, -50%) rotate(5deg);
	position: absolute;
	text-transform: uppercase;
	width: 48px;
	height: 50px;
	left: 50%;
	top: 53%;
	opacity: 0;
	-webkit-transition: ease-in all 0.2s;
	transition: ease-in all 0.2s;
}

.eball:hover .textbox {
	opacity: 1;
	-webkit-transform: translate(-50%, -50%) rotate(0deg);
	        transform: translate(-50%, -50%) rotate(0deg);
	-webkit-transition: ease-in all 0.2s;
	transition: ease-in all 0.2s;
}

.eball:hover .triangle {
	border-top: 90px solid rgba(60, 10, 120, 0.9);
	-webkit-transform: translate(-50%, -50%) rotate(0deg);
	        transform: translate(-50%, -50%) rotate(0deg);
	opacity: 1;
	-webkit-transition: ease-in all 0.2s;
	transition: ease-in all 0.2s;
}

.shadow {
	background: rgba(58, 0, 55, 0.52);
	border-radius: 100%;
	height: 200px;
	width: 200px;
	-webkit-filter: blur(40px);
	        filter: blur(40px);
	position: absolute;
	margin-top: 200px;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%) scaleY(0.1);
	        transform: translate(-50%, -50%) scaleY(0.1);
	z-index: 1;
}

.bg-image__fixed,
.bg-image__horizont {
	min-height: 300px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 0;
}

.bg-image__fixed {
	background-attachment: fixed;
}

.bg-image__horizont {
	min-height: 100vh;
}

.margin-bottom-4 {
	margin-bottom: calc(15px * 4);
}

.margin-2 {
	margin: calc(15px * 2);
}

.project-viewer ul {
	list-style: none;
	margin: 0;
	padding: 0 0 0 1.3em;
}

.project-viewer ul li {
	position: relative;
}

.project-viewer ul li:before {
	content: '—';
	position: absolute;
	left: -1.3em;
}

.project-viewer pre {
	background: rgba(125, 125, 125, 0.05);
	overflow: auto;
}

.letter {
	background-color: rgba(245, 220, 131, 0.32);
	padding: 1rem calc((100% - 60rem) / 2) 1rem calc((100% - 40rem) / 2);
	/*margin: .5rem calc((100% - 40rem) / 2) .5rem 0;*/
}
@media (max-width: 576px) {
	.letter {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

.unit-of-sense {
	padding-top: 4rem;
	padding-bottom: 4rem;
	padding-left: calc((100% - 45rem) / 2);
	padding-right: calc((100% - 45rem) / 2);
	background: #64B5F6;
	color: #fff;
	position: relative;
}

.unit-of-sense:before,
.unit-of-sense:after {
	content: ' ';
	display: block;
	height: 3.5rem;
	background: #fff;
	position: absolute;
	left: 0;
	width: 100%;
}

.unit-of-sense:before {
	top: -1px;
	-webkit-clip-path: polygon(0 100%, 0 0, 100% 0);
	clip-path: polygon(0 100%, 0 0, 100% 0);
}

.unit-of-sense:after {
	bottom: -1px;
	-webkit-clip-path: polygon(0 100%, 100% 100%, 100% 0);
	clip-path: polygon(0 100%, 100% 100%, 100% 0);
}

.unit-of-sense__main {
	font-size: 4rem;
	line-height: 1;
	-webkit-transform: matrix(1, -0.07, 0, 1, 0, 0);
	        transform: matrix(1, -0.07, 0, 1, 0, 0);
	letter-spacing: -0.02em;
	font-weight: 400;
}

.unit-of-sense__note {
	font-style: italic;
	padding-left: calc(100% / 2);
	padding-right: calc((100% - 45rem) / 2);
}

@media (max-width: 576px) {
	.unit-of-sense__main {
		font-size: 1.7rem;
		padding: 0 15px;
	}
	.unit-of-sense__note {
		padding-left: calc((100% - 10rem) / 2);
	}
}

.credits__item {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 200px;
            flex: 1 1 200px;
    margin-right: 15px;
    margin-bottom: 15px;
}

.author__image {
    float: left;
    margin-right: 15px;
    max-width: 50px;
}

.author__information {
    font-size: 12pt;
    opacity: .8;
    overflow: hidden;
}

.author__name {
    margin-bottom: calc(15px / 3);
}

.tourism-walk-gallery__list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	margin: 1rem 0;
	padding: 0;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

.tourism-walk-gallery__list a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: 0 1rem 1rem 0;
	padding: 1rem 2rem 2rem 2rem;
	border-radius: .3rem;
	color: #fff;
	-webkit-transition: all 100ms ease-out;
	transition: all 100ms ease-out;
	border: none;
}

.tourism-walk-gallery__list a:hover {
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
}

.text--large {
	font-size: 3rem;
}

.organisation-list {
	margin-bottom: 30px;
	margin-left: 30px;
	font-size: 1.2rem;
	line-height: 1.8;
	padding: 0;
	list-style-type: none;
}

.organisation-list__item {
	margin-bottom: 7.5px;
}

.album-list {
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.album-list__item.album {
	border: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	width: calc(25% - 30px);
	max-width: 290px;
	margin-right: 15px;
	margin-left: 15px;
	margin-bottom: 40px;
	position: relative;
}

.album__name {
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(21, 216, 234, .3)), to(rgba(32, 73, 185, .9)));
	background: linear-gradient(rgba(21, 216, 234, .3), rgba(32, 73, 185, .9));
	color: #fff;
	padding: 5px 10px;
	position: absolute;
	bottom: 0;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
}

.album__image {
	min-height: 210px;
	width: 100%;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

form.container {
	display: grid;
	grid-template-columns: 9rem 16rem;
	grid-gap: 1rem;
}

.form__button {
	grid-column-start: 1;
	grid-column-end: 3;
}

.project__show-all {
	text-align: center;
}

.pseudo-link {
	cursor: pointer;
	border-bottom: 1px dashed #00000059;
}

.pseudo-link:hover {
	border-color: #000;
}

h1 {
	font-weight: 400;
}

h2 {
	font-size: 24px;
	font-weight: 400;
}

h3 {
	font-weight: 400;
}

.nowrap {
    white-space: nowrap
}

